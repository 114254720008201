// https://dequeuniversity.com/rules/axe/4.7/color-contrast
$primary: #04dfd9;
$link-color: #04625F;

$bootstrap-icons-font-dir: "../../../node_modules/bootstrap-icons/font/fonts"; // relative to the current file
@import "../../../node_modules/bootstrap-icons/font/bootstrap-icons";
@import "../../../node_modules/bootstrap/scss/bootstrap";

.alert p {
  margin-bottom: 0;
}

.dropdown-toggle {
  outline: 0;
}

.btn-toggle, .btn-toggle-no-children {
  padding: .25rem .5rem;
  font-weight: 600;
  color: var(--bs-emphasis-color);
  background-color: transparent;
}

.btn-toggle:hover,
.btn-toggle:focus,
.btn-toggle-no-children.active {
  color: rgba(var(--bs-emphasis-color-rgb), .85);
  background-color: var(--bs-tertiary-bg);
}

.btn-toggle-no-children .bi {
  color: rgba(var(--bs-emphasis-color-rgb), .85);
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: .5em 50%;
  margin-right: 4px;
}

[data-bs-theme="dark"] .btn-toggle::before {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(var(--bs-emphasis-color-rgb), .85);
}

.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  padding: .1875rem .5rem;
  margin-top: .125rem;
  margin-left: 1.25rem;
}

.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus,
.btn-toggle-nav a.active {
  background-color: var(--bs-tertiary-bg);
}

.breadcrumb-chevron {
  --bs-breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%236c757d'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  gap: .5rem;
}

.breadcrumb-chevron .breadcrumb-item {
  display: flex;
  gap: inherit;
  align-items: center;
  padding-left: 0;
  line-height: 1;
}

.breadcrumb-chevron .breadcrumb-item::before {
  gap: inherit;
  float: none;
  width: 1rem;
  height: 1rem;
}

@media (min-width: 1200px) {
  .scrollbar-col {
    height: 100%;
    overflow: auto;
  }
}

.distribution-platform-logo, .platform-logo {
  width: 19px;
  height: 19px;
  fill: currentColor;
}

td p {
  margin-bottom: 0;
}

.toc {
  max-width: 600px;
}

.main-content img {
  margin-bottom: 15px;
}

#search-container {
  min-width: 300px;
}

#search-container button {
  width: 100%;
  margin: 0;
}

blockquote {
  border-left: 3px solid var(--bs-secondary-bg);
  padding-left: 0.5rem;
}

#preamble {
  font-size: 1.25rem;
}
